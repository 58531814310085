import React from 'react';
import { graphql } from 'gatsby';
import BaseTemplate from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import Spacer from '../../components/atoms/utility/Spacer';
import thankYouImage from '../../images/thankyou/thankyou_img.jpg'


function Thankyou() {
  return (
    <BaseTemplate>
      <SEO
        title="Thank You"
        description="For making our day. We are excited to hear your feedback about the flockx mobile app so we can make it even better. We'll be in touch soon"
      />
      <Spacer sizeY={1} className="" />

      <div className="flex flex-col lg:grid lg:gap-24 grid-cols-12 justify-center items-center">
          <div className="col-span-6 order-2 lg:order-1 mx-auto">
            <img src={thankYouImage} alt={''} />
          </div>
          <div className="col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4 order-1 lg:order-2">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5">
              Thank <span className="text-secondary-orange ">You!</span>
            </h3>
            <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl">
              For making our day. We are excited to hear your feedback about the flockx mobile app so we can make it even better. Someone from our team will reach out soon to provide you with more details about what to expect. <br></br><br></br> In the meantime and as a token of our appreciation, here is a gratuitous picture of a puppy.
            </p>
          </div>
        </div>

        <Spacer sizeY={1} className="" />

    </BaseTemplate>
  );
}

export default Thankyou;

// blog-hero-bg-plane
export const pageQuery = graphql`
    query {
        localSearchBlog {
            index
            store
        }
        heroImg: file(name: { eq: "blog-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        mobileHeroImg: file(name: { eq: "blog-mobile-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/blog/" } }
        ) {
            edges {
                node {
                    excerpt
                    timeToRead
                    fields {
                        slug
                    }
                    headerImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        slug
                        localHeaderImage {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        category
                    }
                }
            }
        }
    }
`;
